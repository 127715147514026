// Archivo
@font-face {
  font-family: Archivo;
  font-style: normal;
  font-weight: 400;
  src: local('Archivo'), url(./fonts/Archivo-Regular.woff2) format('woff2');
}
@font-face {
  font-family: Archivo;
  font-style: italic;
  font-weight: 400;
  src: local('Archivo'), url(./fonts/Archivo-Italic.woff2) format('woff2');
}
@font-face {
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
  src: local('Archivo'), url(./fonts/Archivo-Medium.woff2) format('woff2');
}
@font-face {
  font-family: Archivo;
  font-style: italic;
  font-weight: 500;
  src: local('Archivo'), url(./fonts/Archivo-MediumItalic.woff2) format('woff2');
}
@font-face {
  font-family: Archivo;
  font-style: normal;
  font-weight: 600;
  src: local('Archivo'), url(./fonts/Archivo-SemiBold.woff2) format('woff2');
}
@font-face {
  font-family: Archivo;
  font-style: italic;
  font-weight: 600;
  src: local('Archivo'),
    url(./fonts/Archivo-SemiBoldItalic.woff2) format('woff2');
}
@font-face {
  font-family: Archivo;
  font-style: normal;
  font-weight: 700;
  src: local('Archivo'), url(./fonts/Archivo-Bold.woff2) format('woff2');
}
@font-face {
  font-family: Archivo;
  font-style: italic;
  font-weight: 700;
  src: local('Archivo'), url(./fonts/Archivo-BoldItalic.woff2) format('woff2');
}

// ArchivoCondensed
@font-face {
  font-family: ArchivoCondensed;
  font-style: normal;
  font-weight: 400;
  src: local('ArchivoCondensed'),
    url(./fonts/ArchivoCondensed-Regular.woff2) format('woff2');
}
@font-face {
  font-family: ArchivoCondensed;
  font-style: italic;
  font-weight: 400;
  src: local('ArchivoCondensed'),
    url(./fonts/ArchivoCondensed-Italic.woff2) format('woff2');
}
@font-face {
  font-family: ArchivoCondensed;
  font-style: normal;
  font-weight: 500;
  src: local('ArchivoCondensed'),
    url(./fonts/ArchivoCondensed-Medium.woff2) format('woff2');
}
@font-face {
  font-family: ArchivoCondensed;
  font-style: italic;
  font-weight: 500;
  src: local('ArchivoCondensed'),
    url(./fonts/ArchivoCondensed-MediumItalic.woff2) format('woff2');
}
@font-face {
  font-family: ArchivoCondensed;
  font-style: normal;
  font-weight: 600;
  src: local('ArchivoCondensed'),
    url(./fonts/ArchivoCondensed-SemiBold.woff2) format('woff2');
}
@font-face {
  font-family: ArchivoCondensed;
  font-style: italic;
  font-weight: 600;
  src: local('ArchivoCondensed'),
    url(./fonts/ArchivoCondensed-SemiBoldItalic.woff2) format('woff2');
}
@font-face {
  font-family: ArchivoCondensed;
  font-style: normal;
  font-weight: 700;
  src: local('ArchivoCondensed'),
    url(./fonts/ArchivoCondensed-Bold.woff2) format('woff2');
}
@font-face {
  font-family: ArchivoCondensed;
  font-style: italic;
  font-weight: 700;
  src: local('ArchivoCondensed'),
    url(./fonts/ArchivoCondensed-BoldItalic.woff2) format('woff2');
}

// ArchivoBlack
@font-face {
  font-family: ArchivoBlack;
  font-style: normal;
  font-weight: 400;
  src: local('ArchivoBlack'), url(./fonts/Archivo-Black.woff2) format('woff2');
}
@font-face {
  font-family: ArchivoBlack;
  font-style: italic;
  font-weight: 400;
  src: local('ArchivoBlack'),
    url(./fonts/Archivo-BlackItalic.woff2) format('woff2');
}

// Headers
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Archivo', sans-serif;
}

h1.documentFirstHeading {
  border: none;
  margin-bottom: 1.5rem;
  font-family: 'ArchivoBlack', sans-serif;
  font-size: 48px;
  font-weight: 400;
  line-height: 56px;
  @media only screen and (max-width: $largest-mobile-screen) {
    margin-bottom: 1rem;
    font-size: 36px;
    font-weight: 400;
    line-height: 40px;
  }
}

@mixin h2_base() {
  h2 {
    padding-top: 2rem;
    margin-bottom: 10px !important;
    font-family: 'Archivo', sans-serif;
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 30px !important;
    @media only screen and (max-width: $largest-mobile-screen) {
      margin-right: 2rem !important;
      margin-left: 2rem !important;
      text-align: center !important;
    }
  }
}

@mixin h2_block() {
  .content {
    @include h2_base();
  }
}

@mixin h2_headline() {
  h2.headline {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 30px !important;
    font-weight: 400 !important;
    line-height: 36px !important;
    @media only screen and (max-width: $largest-mobile-screen) {
      margin-right: 2rem !important;
      margin-left: 2rem !important;
      text-align: center !important;
    }
  }
}
