.block.gridBlock {
  margin-top: 0;
  margin-bottom: 0;

  .block.teaser {
    margin-bottom: 0;
    background-color: $lightgrey;
    .grid-teaser-item {
      height: 100%;

      .grid-image-wrapper {
        margin: 0 0 40px 0 !important;

        img {
          position: relative;
        }
      }
      .content {
        padding: 0 20px 20px 20px;
        @include body-text();

        .headline {
          padding: 0 !important;
          margin-bottom: 20px;
          letter-spacing: 1px;
          text-transform: uppercase;
          @include headtitle1();
        }

        h2 {
          padding: 0;
          margin-bottom: 20px;
        }

        p {
          margin-bottom: 20px;
        }
      }
    }
  }

  .block-editor-teaser {
    padding: 0;
    margin: 0.5rem !important;
    .grid-teaser-item {
      border: none !important;
    }
  }

  .slate:not(.inner) {
    padding: 40px 20px 20px 20px;
    margin: 0;
    background-color: $lightgrey;
    p {
      padding: 0;
      margin-bottom: 20px;
    }
  }

  .block-editor-slate {
    padding: 0;
  }

  .block.image {
    figcaption {
      position: absolute;
      z-index: 100;
      bottom: 0;
      padding: 0 1rem 0 1rem;
      margin: 0;

      .title {
        margin: 0 0 20px 0;
        color: $white !important;
        letter-spacing: 1px;
        @include text-heading-h2();
      }

      .description {
        display: none;
      }

      .credits {
        margin-bottom: 0;
        color: $white !important;
        font-size: 10px;
      }
    }
    img {
      z-index: revert !important;
    }
    figure {
      position: relative;
      display: flex;
      justify-content: flex-end;
      margin: 0 !important;
    }

    figure:after {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 76px;
      background: $black;

      content: '';
      opacity: 0.75;
    }
  }

  .block-editor-image {
    padding: 0;
    margin: 0.5rem !important;
  }

  .block-editor-empty {
    padding: 0;
    margin: 1rem 0.5rem 1rem 0.5rem !important;
  }
}

// Dynamic font sizes depending on number of columns for in-grid blocks
.block.gridBlock,
.block-editor-gridBlock {
  .one {
    .grid-teaser-item .content h2 {
      margin-bottom: 40px !important;
      @include text-heading-h2();
    }

    .slate {
      h2 {
        margin-bottom: 40px !important;
      }
    }
  }

  .two,
  .three {
    .teaser {
      .grid-teaser-item .content {
        h2 {
          margin-bottom: 20px !important;
          @include text-heading-h3();
        }
      }
    }
    .image {
      figcaption {
        .title {
          @include text-heading-h3();
          margin: 0 0 20px 0;
        }
      }
      figure:after {
        height: 70px;
      }
    }
    .slate {
      h2 {
        margin-bottom: 20px !important;
        @include text-heading-h3();
      }
    }
  }

  .four {
    .teaser {
      .grid-teaser-item {
        .grid-image-wrapper {
          margin-bottom: 20px !important;
        }
        .content {
          .headline {
            @include headtitle2();
          }
          h2 {
            margin-bottom: 20px !important;
            @include text-heading-h4();
          }
          p {
            margin-bottom: 0;
          }
        }
      }
    }

    .image {
      figcaption {
        .title {
          margin: 0 0 15px 0;
          color: $white !important;
          letter-spacing: 1px;
          @include text-heading-h4();
        }
      }
      figure:after {
        height: 59px;
      }
    }

    .slate {
      h2 {
        margin-bottom: 20px !important;
        @include text-heading-h4();
      }

      padding-top: 20px !important;
    }
  }
}

.block.gridBlock.has--backgroundColor--grey,
#page-add .block-editor-gridBlock.has--backgroundColor--grey,
#page-edit .block-editor-gridBlock.has--backgroundColor--grey {
  background-color: $lightgrey;
  .grid-teaser-item {
    background: white;
  }
  .slate {
    padding: 0 !important;
    margin: 0 1rem 1rem 1rem;
    background-color: $white;
  }

  .slate-editor {
    padding: 0 !important;
    margin: 0 1rem 1rem 1rem;
    background-color: $white !important;
  }

  .grid-block-image {
    .block.image {
      background: white;
    }
  }
}

// Teasers in grids specific
#page-document .block.gridBlock .block.teaser {
  margin-bottom: 0;
}
