.header-wrapper .header {
  display: flex;
  flex-direction: column-reverse;

  .logo-nav-wrapper {
    display: flex;
    flex-direction: row;
    flex-grow: 2;
    align-items: baseline;
    justify-content: space-between;
    padding-top: 2rem;
    padding-bottom: 1rem;

    .logo {
      align-self: center;
    }

    .navigation {
      display: flex;
      flex-grow: 2;
      align-self: center;
      justify-content: center;
      @media only screen and (max-width: $computer-width) {
        justify-content: flex-end;
      }

      .desktop.menu {
        display: flex;
        justify-content: flex-end;

        a.item {
          padding: 0 5px;
          margin: 0 5px;
          color: $black;
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
        }
      }

      .mobile-menu {
        .mobile-menu-nav {
          > div {
            display: flex;
            flex-direction: column;
          }

          a.item {
            width: 100%;
            padding: 2rem 1.2rem 1.5rem 1.2rem;
            color: $black;
            font-size: 18px;

            &.active {
              border-bottom: 4px solid $black;
            }
          }
        }
      }
    }
  }
  .search-wrapper {
    display: flex;
    flex-direction: row-reverse;

    .search {
      .searchbox {
        display: flex;

        input {
          border: none;

          &:focus {
            outline: none;
          }
        }
      }

      .search-button {
        text-align: right;
      }

      button {
        width: 75px;
        height: 75px;
        border: none;
        background-color: transparent;
        border-radius: 50%;
        transition: background-color 200ms ease-in-out, color 300ms ease-in-out;

        svg {
          transition: transform 300ms ease-in-out;
          transition-property: transform;
        }

        &:hover {
          background-color: $darkGrey;
          color: $white;
          cursor: pointer;

          svg {
            transform: scale(0.75);
          }
        }
        &:active {
          transform: scale(0.85);
          transition-duration: 100ms;
          transition-property: transform;
        }
      }

      .search-bar {
        position: absolute;
        z-index: 10;
        top: -135px;
        left: 0;
        width: 100%;
        height: 90px;
        padding-top: 2rem;
        background-color: $lightgrey;
        transition: top 500ms ease-in-out, height 600ms ease-in-out;
        transition-property: top height;

        &.active {
          top: 0;
          height: 190px;

          @media only screen and (max-width: $largest-mobile-screen) {
            height: 232px;
          }
        }

        .ui.container {
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: center;

          form {
            width: 100%;

            .searchbox {
              justify-content: space-between;
              padding-top: 0;
              padding-bottom: 0.5rem;
              border-bottom: 2px solid $black;
              border-left: none;
              margin-left: 12.6rem;

              @media only screen and (max-width: $tablet-breakpoint) {
                padding-top: 2rem;
                margin-left: 0.5rem;
              }
              @media only screen and (max-width: $large-monitor-breakpoint) {
                padding-top: 0;
              }

              input {
                overflow: hidden;
                width: 70%;
                padding-left: 0;
                margin-right: 1rem;
                background-color: $lightgrey;
                color: $black;
                font-size: 1.5rem;
                @media only screen and (min-width: $tablet-breakpoint) and (max-width: 788px) {
                  margin-left: 1.5rem;
                }

                &::placeholder {
                  color: $black;
                  opacity: 0.5;
                }
              }

              button {
                width: 75px;
                height: 75px;
                padding: 10px;
                margin-right: 7px;

                &:hover {
                  background-color: $darkGrey;
                  border-radius: 50%;
                  svg {
                    fill: $white !important;
                  }
                }

                svg {
                  fill: $black !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .tools-wrapper {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 16px;

    .language-selector {
      padding-left: 40px;
    }

    .language-selector a {
      text-transform: uppercase;
    }
    .tools {
      display: flex;
    }
    .tools a {
      margin-right: 10px;
      color: $black;
      font-size: 14px;
      text-align: center;
    }

    .tools a::after {
      padding-right: 0;
      padding-left: 10px;
      content: '|';
      font-size: 1rem;
    }

    .tools a:last-child:after {
      padding-right: 5px;
      padding-left: 5px;
      content: '';
    }

    .tools .anontools {
      display: flex;
      margin-right: 10px;

      a {
        margin-right: 0;
        color: $black;
      }

      > div {
        &:first-of-type::after {
          content: '|';
          font-size: 1rem;
        }
      }
    }
  }
}
