body.contenttype-person {
  .blocks-form,
  .blocks-group-wrapper {
    .block.image {
      img {
        max-width: 200px;
        max-height: 200px;
        border-radius: 15%;
      }
    }
  }
}
