.ui.container.social-networks {
  .social-network {
    .icon {
      color: $brandconstrast;

      &:hover {
        color: $brandconstrast;
        @include animate-hover();
      }
    }
  }
}
