$breadcrumbHeight: 20px !important;

#main .breadcrumbs {
  padding: 0.8em 0;
  background-image: url('images/background.png');

  .breadcrumb {
    height: $breadcrumbHeight;

    .home {
      height: $breadcrumbHeight;
      margin-right: 0.5em;

      .icon {
        height: $breadcrumbHeight;
        color: $brandconstrast;
      }
    }

    .divider::before {
      color: $brandconstrast;
    }

    .section {
      color: $brandconstrast;
    }
    @media only screen and (max-width: $largest-mobile-screen) {
      div:last-of-type,
      div:nth-of-type(2) {
        display: none;
      }
    }
  }
}
