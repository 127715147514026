#page-document .block.separator {
  // cancel the original padding from the add-on
  &:not(.inner):not([role='presentation']) {
    padding: 0 !important;
  }

  &.has--align--center {
    margin-right: auto;
    margin-left: auto;
  }

  @include vertical-space-separator();
}
