#page-add,
#page-edit {
  .grid-items {
    .block-editor-tweetBlock {
      margin-right: 0rem;
      margin-left: 0rem;
    }

    &.one {
      .block-editor-tweetBlock {
        max-width: 1100px;
      }
    }

    &.two {
      .block-editor-tweetBlock {
        max-width: 550px;
      }
    }

    &.three {
      .block-editor-tweetBlock {
        max-width: 366px;
      }
    }

    &.four {
      .block-editor-tweetBlock {
        max-width: 225px;
      }
    }
  }
}
