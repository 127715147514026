#page-add,
#page-document,
#page-edit {
  div.block.slider {
    &.next--is--separator {
      margin-bottom: 2rem;
    }

    .slick-slider {
      width: var(--narrow-container-width) !important;
      max-width: var(--narrow-container-width) !important;
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.slick-slide {
  .highlight-image-wrapper {
    max-height: 350px;

    img {
      object-fit: contain !important;
    }
  }
}

.block.slider .teaser-item-title {
  @include h2_base();
  top: 50%;
  width: 500px;
  min-height: 300px;
  max-height: 350px;
  padding: 50px 35px 50px 15px;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  transform: translateY(-50%);
}
