.header-wrapper .header .logo-nav-wrapper {
  padding-top: 0rem;
  padding-bottom: 0rem;

  .logo {
    img {
      max-width: 350px;
      @media only screen and (max-width: $largest-mobile-screen) {
        max-width: 250px;
      }
    }
  }
}
