.header-wrapper {
  .header {
    .search-wrapper {
      @media only screen and (max-width: $largest-mobile-screen) {
        display: none;
      }
    }
  }

  &.sticky {
    position: fixed;
    z-index: 80;
    top: 0;
    width: 100%;
    background: $page-background;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    @media only screen and (max-width: $largest-mobile-screen) {
      padding-left: 0px;
    }

    > .container.layout {
      max-width: 100%;
    }
  }
}
