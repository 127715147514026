body.section-sobre-nos {
  .block.gridBlock {
    .block.teaser {
      .teaser-item.default {
        .content {
          padding: 0 1.5rem 2rem 1.5rem;
        }
      }
    }
  }
}
