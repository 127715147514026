.block.listing {
  &.summary {
    .listing-item {
      img {
        min-width: 20%;
        max-width: 20%;
        margin-right: 20px;
        aspect-ratio: 16 / 9;
        object-fit: cover;
      }
    }
  }

  &.grid {
    @include h2_headline();

    .block.teaser {
      @include h2_block();

      .image-wrapper {
        margin-bottom: 10px;
      }

      .content {
        padding: 0 1rem 2.5rem;
      }

      &:hover {
        @include animate-hover();
      }
    }
  }
}
