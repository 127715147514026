// Separator
.block.separator .line::after {
  position: relative;
  top: 50%;
  display: block;
  border-top: 1px solid $brand;
  margin-top: -1px;
  content: '';
}

#page-document .block.separator {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
