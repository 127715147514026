.block.__button {
  .button.container {
    @include narrow-container-width();

    @include vertical-space-button();
  }
}

.block-editor-__button.has--buttonAlign--wide {
  .button.container {
    @include default-container-width();
  }
}

.block.__button.has--buttonAlign--wide {
  .button.container {
    @include default-container-width();
  }
}

.block.__button .ui.button {
  padding: 8px 20px;
  border: 1px solid $black;
  background: none;
  border-radius: unset;
  color: $black;

  @include body-text-bold();
}
