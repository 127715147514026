// This theme extends the volto-light-theme
// it is injected

:root {
  --narrow-container-width: 1100px;
  --default-container-width: 1100px;
  --teaser-images-aspect-ratio: #{$teaser-images-aspect-ratio};
}

@import 'root';
@import 'typography';
@import 'toolbar';
@import 'breadcrumbs';
@import 'logo';
@import 'header';
@import 'search';
@import 'tags';
@import 'navigation';
@import 'navigation_submenu';
@import 'footer';
@import 'socialnetworks';
@import 'types/person';
@import 'blocks/blocks';
@import 'blocks/button';
@import 'blocks/documentByLine';
@import 'blocks/form';
@import 'blocks/grid';
@import 'blocks/listing';
@import 'blocks/separator';
@import 'blocks/slider';
@import 'blocks/social';
@import 'areas/integrantes';
@import 'areas/sobre';
@import 'admin/types';
