.highlight {
  .title h2 {
    @include highlight-title();
  }
  .description p {
    @include introduction();
  }
  .button a {
    @include body-text();
  }
}
