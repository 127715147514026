.block.gridBlock {
  @include h2_headline();

  .block.teaser {
    @include h2_block();

    .image-wrapper {
      margin-bottom: 10px;
    }

    .content {
      padding: 0 1rem 2.5rem;
    }

    &:hover {
      @include animate-hover();
    }
  }
}

#page-document .block.gridBlock {
  &.next--is--separator.next--has--same--backgroundColor {
    margin-bottom: 20px !important;
  }
}

#page-document
  .blocks-group-wrapper
  .block.gridBlock.is--first--of--block-type.previous--has--same--backgroundColor {
  margin-top: 20px !important;
}

#page-add,
#page-edit,
#page-document {
  .blocks-group-wrapper
    .block.gridBlock.is--last--of--block-type.next--has--same--backgroundColor {
    margin-bottom: 30px;
  }
}
