body.has-toolbar .submenu-wrapper {
  padding-left: 80px;
}

body.has-sidebar .submenu-wrapper {
  padding-right: 375px;

  .submenu .close {
    right: 450px;
  }
}

.submenu-wrapper {
  // position: absolute;
  z-index: 10;
  // right: 0;
  // // top: 100%;
  // left: 0;
  width: 100%;

  .submenu {
    display: none;
    height: auto;
    padding: 50px 102px 70px;
    background: $background-containers;

    @media only screen and (max-width: $largest-mobile-screen) {
      padding: 40px 40px;
    }

    ul {
      display: flex;
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 0;
      padding-top: 30px;
      list-style: none;

      .sub-submenu ul {
        display: flex;
        flex-direction: column;
        padding-top: 0;

        li {
          padding: 0;
          padding-bottom: 10px;
          color: $black;

          a {
            font-size: 15px;
            font-weight: 400;
          }
        }
      }
    }

    .close {
      position: absolute;
      top: 27px;
      right: 75px;
      color: $black;
      cursor: pointer;
    }

    &-inner {
      width: 100%;

      h2 {
        position: relative;
        display: inline-block;
        padding-left: 25px;
        color: $black;
        font-size: 24px;
        font-weight: bold;
        text-align: left;

        .left-arrow {
          position: absolute;
          top: -4px;
          left: -43px;
        }
      }

      .subitem-wrapper {
        width: 20%;
        padding: 0 20px 0 10px;
        padding-left: 30px;
        border-left: 1px solid #d5dde2;
        @media only screen and (min-width: 1011px) and (max-width: 1280px) {
          width: 33.33%;
        }

        .left-arrow {
          position: absolute;
          left: -43px;
          font-family: 'Arial';
          font-size: 50px;
          transform: rotate(270deg);
          visibility: hidden;
        }

        &:nth-of-type(5n) {
          padding-right: 0;
        }

        &:nth-of-type(n + 6) {
          margin-top: 70px;
          // padding: 0 80px 0 10px;
        }

        @media only screen and (min-width: 1011px) and (max-width: 1280px) {
          &:nth-of-type(n + 4) {
            margin-top: 70px;
          }
        }
        @media only screen and (min-width: 1011px) and (max-width: 1280px) {
          &:nth-of-type(3n) {
            padding-right: 0;
          }
        }

        li {
          position: relative;
          padding-top: 0;
        }

        li:hover {
          .left-arrow {
            visibility: visible;
          }
        }

        a {
          span {
            @include word-break;
          }
        }
      }
    }

    .homepage-title {
      border-color: #fff;
      color: #fff;
    }

    &.active {
      display: flex;
    }

    li {
      padding: 8px 0;
    }

    li a {
      display: inline-flex;
      align-items: flex-start;
      color: #fefefe;
      font-size: 22px;
      font-weight: 400;

      span {
        border-bottom: 1px solid transparent;
        line-height: 30px;
        word-break: break-word;
      }

      span:hover {
        border-bottom: 1px solid #fefefe;
      }

      img {
        height: 26px;
        margin-right: 10px;
      }
    }
  }
}
