// DocumentByLine
#page-document,
#page-edit {
  .block.documentByLine {
    max-width: var(--default-container-width);
    margin-right: auto;
    margin-left: auto;

    .ui.container.info {
      display: inline;
      font-size: medium;

      &.author {
        .name {
          font-size: medium;
        }
      }

      :after {
        padding-right: 0.5em;
        padding-left: 0.5em;
        content: ' • ';
      }

      &:last-child {
        :after {
          padding-right: 0;
          padding-left: 0;
          content: '';
        }
      }
    }
    @media only screen and (max-width: $largest-mobile-screen) {
      .ui.container.documentByLine {
        margin-right: 2rem !important;
        margin-left: 2rem !important;
      }

      .ui.container.info {
        margin-right: 5px !important;
        margin-left: 0px !important;

        :after {
          padding-right: 0.1em;
          padding-left: 0.1em;
        }

        &.effective {
          display: none;
        }
      }
    }
  }
}
