// Listing Block
.block.listing.default {
  .headline {
    @include block-title();
  }
  .listing-item {
    padding-bottom: 25px;
    border-bottom: 1px solid $black;
    a {
      .listing-body {
        h2 {
          color: $black;
          @include text-heading-h2();
        }
        p {
          margin-bottom: 0;
          @include body-text();
        }
        p:empty {
          display: none;
        }
      }
    }
  }
}

// Grid Listing Block

.block.listing.grid {
  .items {
    display: flex;
    flex-wrap: wrap;
    @media only screen and (max-width: $tablet-breakpoint) {
      flex-direction: column;
    }
  }
  .headline {
    @include block-title();
  }
  .listing-item {
    @media only screen and (min-width: $tablet-breakpoint) {
      width: 50%;
    }
    margin-bottom: 0;

    .card-container {
      width: 100%;
      margin: 0.5rem;
      @media only screen and (max-width: $tablet-breakpoint) {
        margin-left: 0;
      }
      background-color: $lightgrey;
    }

    img.grid-item-image {
      width: 100%;
    }

    .content {
      padding: 0 1rem 2.5rem 1rem;

      h2 {
        padding-top: 2rem;
        margin-bottom: 0;
        color: $black;
        @include text-heading-h3();
      }
      p {
        margin-bottom: 0;
        @include body-text();
      }
      p:empty {
        display: none;
      }
    }
  }
}
