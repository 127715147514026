.blocks-group-wrapper {
  padding-top: $color-block-change-vertical-spacing;
  padding-bottom: $color-block-change-vertical-spacing;
}

.blocks-group-wrapper.grey {
  background-color: #ecebeb;
}

#page-document .blocks-group-wrapper {
  &.transparent:first-child {
    padding-top: 0;
  }
  .block:first-child h2,
  .block:first-child h2.headline {
    margin-top: 0;
  }
}

// If next block has different bgcolor, cancel all margin-bottom
#page-document .block.next--has--different--backgroundColor {
  margin-bottom: 0 !important;
}

// For grids
#page-document .blocks-group-wrapper .block.gridBlock {
  &.is--first--of--block-type.previous--has--same--backgroundColor {
    margin-top: $grid-block-vertical-spacing-top;
  }
  &.is--last--of--block-type.next--has--same--backgroundColor {
    margin-bottom: $grid-block-vertical-spacing-bottom;
  }
  &.next--is--same--block-type.next--has--same--backgroundColor {
    margin-bottom: -1rem;
  }
}

// Buttons
#page-document
  .blocks-group-wrapper
  .block.__button.next--has--different--backgroundColor {
  margin-bottom: 0;

  .button.container {
    margin-bottom: 0;
  }
}
