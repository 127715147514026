#footer {
  margin-top: 1rem;
  background-color: $background-containers;
  background-image: url('images/background.png');
  color: $brandconstrast;
  text-align: center;

  a {
    color: $brandconstrast;
    text-decoration: none;

    &:hover {
      color: $brandconstrast;
      text-decoration: none;
    }
  }

  .footer {
    max-width: var(--narrow-container-width);
    padding: 1.8rem 1rem;
    background-color: transparent !important;
    font-size: 18px;

    .navFooter {
      .navSection {
        .navTitle {
          margin-top: 1rem;
          margin-bottom: 0;
          color: $brandconstrast;
          font-size: 20px;
          text-align: center;
          @media only screen and (max-width: $largest-mobile-screen) {
            padding-top: 0.2rem;
            margin-top: 0.2rem;
          }
        }

        .details {
          display: block;
          margin-top: 1rem;
          color: $brandconstrast;
          text-align: center;

          li {
            padding-left: 0;
            border-right: 0;
          }
        }
      }
    }

    ul {
      li {
        border-right: 1px solid $brandconstrast;
      }
    }
  }

  .signature {
    max-width: 100%;
    padding: 1rem 1rem;
    background-color: $black;
    color: $grey;
    font-size: 18px;

    a {
      color: $grey;
      text-decoration: none;

      &:hover {
        color: $lightgrey;
        text-decoration: none;
      }
    }
  }

  .ui.container.social-networks {
    margin-top: 10px;
  }
}
