#navigation.navigation {
  min-height: 30px;
  text-transform: uppercase;

  .item {
    &.active,
    &:hover {
      padding-bottom: 5px;
      border-bottom: 5px solid $brand;
    }
  }
}
